import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import "./css/style.css";
import StoragePlugin from 'vue-web-storage'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import store from './store';

const app = createApp(App).use(router).use(store)
// .use(SimpleTypeahead)

.use(StoragePlugin, {
  prefix: 'sess_',// default `app_`
  drivers: ['session', 'local'], // default 'local'
});
app.component('EasyDataTable', Vue3EasyDataTable);
app.config.globalProperties.$swal = Swal;
app.mount('#app');
