<template>
  <div class="connexion fullscreen">
    <!-- En-tête -->
    <!-- <span class = "d-block p-2 bg-primary"></span> -->
    <header>
      <div class="banner-style">
        <div class="container pd-18">
          <a class="logo-header" href="/" title="retour à l'accueil">
            <img src="../assets/svg/Logo_Autoref.svg" />
          </a>
        </div>
      </div>
    </header>
    <!-- <span class="d-block p-2 bg-dark"></span> -->
    <main>
      <div class="container mt-20">
        <h1 class="admin-autoref">Admininistration</h1>
        <form class="mt-20" @submit.prevent="VerifierLogin">
          <div class="row row-label">
            <label for="log_log">Identifiant</label>
            <label for="pwd_log">Mot de passe</label>
          </div>
          <div class="row row-form px-10">
            <input
              type="text"
              class="input-lg form-control fs21"
              placeholder="Identifiant"
              v-model="log_log"
              id="log_log"
            />
            <input
              type="password"
              class="input-lg form-control"
              placeholder="Mot de passe"
              v-model="pwd_log"
              id="pwd_log"
              ref="pwd_ref"
            />
            <button type="submit" id="connexion" class="btn alert-primary fs20">
              Se connecter
            </button>
          </div>
        </form>
        <!-- ERREUR DE SAISIE - AFFICHAGE DU MESSAGE D'ERREUR POUR L'UTILISATEUR -->
        <div v-if="retourError" class="alert alert-warning fs18 center-h mt-15">
          Login ou Mot de passe incorrect
        </div>
      </div>
      <div v-if="showInstallPopup && !isDesktop" class="install-popup">
        <p>Voulez-vous ajouter cette application à votre écran d'accueil ?</p>
        <button @click="installApp">Oui</button>
        <button @click="closePopup">Non</button>
      </div>

      <!-- Formulaire d'aide pour iOS -->
      <div v-if="isIos && !isInStandaloneMode && !isDesktop" class="ios-help">
        <p>Pour ajouter cette application à l'écran d'accueil :</p>
        <ol>
          <li>Tapez sur <strong>l'icône de partage</strong> <img src="share-icon.png" alt="Icône de partage" class="icon" /> en bas de l'écran.</li>
          <li>Sélectionnez <strong>"Ajouter à l'écran d'accueil"</strong>.</li>
        </ol>
        <button @click="closeIosHelp">Fermer</button>
      </div>
    </main>
    <!-- Modal -->
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <button class="close-button" @click="closeModal" id="fermerModal">X</button>
        <div id="contenuModal">
          <div class="header-titre fs20">
            <div>Login bastion : {{ this.log_log }}</div>
          </div>
          <div class="row row-input">
            <label class="label" for="id_adherent">Instance de connexion :</label>
            <select name="id_adherent" id="id_adherent" class="select" v-model="selectedAdh">
              <option value="">Sélectionnez une instance de connexion</option>
              <option v-for="dataAdh in tabAdh" v-bind:key="dataAdh.ID_ADHERENT" v-bind:value="{id:dataAdh.ID_ADHERENT,nom_adh:dataAdh.NOM_ADH,ins_adh:dataAdh.INS_ADH}">
                {{ dataAdh.NOM_ADH }} ({{ dataAdh.INS_ADH }})
              </option>
            </select>
          </div>
        </div>
        <div class="footerModal">
          <button type="button" class="btn-valider alert-primary fs12" name="btnValide" id="btnValide" @click="ValiderSaisie">
            <i class="fa fa-check"></i> Connexion
          </button>
          <button type="button" class="btn-annuler alert-danger fs12" name="btnAnnuler" id="btnAnnuler" @click="closeModal">
            <i class="fa fa-times"></i> Annuler
          </button>
        </div>
      </div>
    </div>
    <!-- FIN Modal -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";

const router = useRouter();
export default {
  name: "index",
  components: {},
  // directives: {
    // focus: {
    //   mounted(el) {
    //     el.focus();
    //   },
    // },
  // },
  data() {
    return {
      log_log: "",
      pwd_log: "",
      retourError: false,
      showModal: false,
      tabAdh:[],
      selectedAdh:[],
      deferredPrompt: null,
      showInstallPopup: false,
      isIos: false,
      isInStandaloneMode: false,
      isDesktop:false,
    };
  },
  mounted() {
    // Détection iOS
    this.isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    
    // Vérification si l'app est déjà en mode standalone
    this.isInStandaloneMode = ('standalone' in window.navigator) && window.navigator.standalone;
    this.isDesktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // Pour Android/Chrome, gestion de l'événement beforeinstallprompt
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showInstallPopup = true;  // Affiche le popup pour Android
      console.log('beforeinstallprompt event captured');
    });
  },
  methods: {
    installApp() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();  // Affiche le prompt d'installation natif
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log("L'utilisateur a accepté d'installer l'application.");
          } else {
            console.log("L'utilisateur a refusé d'installer l'application.");
          }
          this.deferredPrompt = null;  // Réinitialise l'objet
          this.showInstallPopup = false; // Ferme le popup
        });
      }
    },
    closePopup() {
      this.showInstallPopup = false;  // Ferme le popup
    },
    closeIosHelp() {
      this.isIos = false;  // Masque l'aide pour iOS
    },
    closeModal() {
      this.showModal = false;
    },
    ValiderSaisie(){
      if(this.selectedAdh["id"]=="")
      {
        this.$swal.fire({
          icon: 'error',
          title: "Veuillez sélectionner une instance !",
          showCloseButton: true,
          allowOutsideClick: false,
        }).then(() => {
          document.getElementById("id_adherent").focus();
        });
        return false;
      }
      else
      {
        this.$sessionStorage.set("instance", this.selectedAdh["ins_adh"]);
        this.$sessionStorage.set("nom_adh", this.selectedAdh["nom_adh"]);
        this.$sessionStorage.set("id_adherent", this.selectedAdh["id"]);
        this.$router.push({ name: "Portail" });
      }
    },
    VerifierLogin: function (e) {
      var prog = "./src/req/VerifierLogin.php";
      // envoie des données dans le POSTS
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          log_log: this.log_log,
          pwd_log: this.pwd_log,
        }),
      };
      fetch(prog, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          switch(data.retour)
          {
            case 'OK':
              if(data.sta_log=="A")
              {
                this.$sessionStorage.set("log_log", this.log_log),
                this.$sessionStorage.set("pwd_log", this.pwd_log),
                this.$sessionStorage.set("instance", data.ins_adh),
                this.$sessionStorage.set("nom_adh", data.nom_adh),
                this.$sessionStorage.set("id_filogin", data.id_filogin),
                this.$sessionStorage.set("lib_log", data.lib_log),
                this.$sessionStorage.set("id_adherent", data.id_adherent),
                this.$router.push({ name: "Portail" });
              }
              else
              {
                this.$sessionStorage.set("log_log", this.log_log),
                this.$sessionStorage.set("pwd_log", this.pwd_log),
                this.$sessionStorage.set("lib_log", data.lib_log),
                this.$sessionStorage.set("id_filogin", data.id_filogin),
                this.showModal=true;
                this.tabAdh=data.tabAdh;
              }
            break;
            case 'ERREUR':
              this.retourError = true;
              this.pwd_log = "";
              this.$refs.pwd_ref.focus();
            break;
            case 'DESAC':
              this.$swal.fire({
                icon: 'error',
                title: 'Votre login est désactivé, veuillez contacter votre administrateur.',
                showCloseButton: true,
                allowOutsideClick: false,
              }).then(() => {
                return false;
              });
            break;
          }
        });
    },
  },
};
</script>
