// store/modules/breadcrumb.js
export default {
  state: {
    crumbs: [{ text: 'Portail', link: { name: 'Portail' } }],
    selectedApp: null,
    id_metier: null,
    lib_met: null,
    applicatifListe: [],
    subApplicatifs: [],
    metierListe: [],
    moduleListe: [],
  },
  mutations: {
    ADD_CRUMB(state, crumb) {
      state.crumbs.push(crumb);
    },
    RESET_CRUMBS(state) {
      state.crumbs = [{ text: 'Portail', link: { name: 'Portail' } }];
    },
    REMOVE_CRUMB(state, index) {
      state.crumbs.splice(index + 1);
    },
    SET_SELECTED_APP(state, app) {
      state.selectedApp = app;
    },
    SET_METIER(state, { id_metier, lib_met }) {
      state.id_metier = id_metier;
      state.lib_met = lib_met;
    },
    SET_APPLICATIF_LIST(state, list) {
      state.applicatifListe = list;
    },
    SET_SUB_APPLICATIF_LIST(state, list) {
      state.subApplicatifs = list;
    },
    SET_METIER_LIST(state, list) {
      state.metierListe = list;
    },
    SET_MODULE_LIST(state, list) {
      state.moduleListe = list;
    }
  },
  actions: {
    addCrumb({ commit }, crumb) {
      commit('ADD_CRUMB', crumb);
    },
    resetCrumbs({ commit }) {
      commit('RESET_CRUMBS');
    },
    removeCrumb({ commit }, index) {
      commit('REMOVE_CRUMB', index);
    },
    setSelectedApp({ commit }, app) {
      commit('SET_SELECTED_APP', app);
    },
    setMetier({ commit }, { id_metier, lib_met }) {
      commit('SET_METIER', { id_metier, lib_met });
    },
    setApplicatifList({ commit }, list) {
      commit('SET_APPLICATIF_LIST', list);
    },
    setSubApplicatifList({ commit }, list) {
      commit('SET_SUB_APPLICATIF_LIST', list);
    },
    setMetierList({ commit }, list) {
      commit('SET_METIER_LIST', list);
    },
    setModuleList({ commit }, list) {
      commit('SET_MODULE_LIST', list);
    }
  },
  getters: {
    getCrumbs: (state) => state.crumbs,
    selectedApp: (state) => state.selectedApp,
    idMetier: (state) => state.id_metier,
    libMet: (state) => state.lib_met,
    applicatifList: (state) => state.applicatifListe,
    subApplicatifList: (state) => state.subApplicatifs,
    metierList: (state) => state.metierListe,
    moduleList: (state) => state.moduleListe
  }
};
