/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import PageIndex from '../views/Index.vue'
const routes = [
  {
    path: '/',
    name: 'Index',
    component: PageIndex 
  },
  {
    path: '/Portail',
    name: 'Portail',
    component: () => import('../views/Portail.vue')
  },
  //Ajouter les routes des différents programmes ici :
  {
    path: '/MaintModule',
    name: 'MaintModule',
    component: () => import('../views/MaintModule.vue')
  },
  {
    path: '/MaintCategorie',
    name: 'MaintCategorie',
    component: () => import('../views/MaintCategorie.vue')
  },
  {
    path: '/MaintDisplay',
    name: 'MaintDisplay',
    component: () => import('../views/MaintDisplay.vue')
  },
  {
    path: '/MaintMetier',
    name: 'MaintMetier',
    component: () => import('../views/MaintMetier.vue')
  },
  {
    path: '/MaintTeccategorie',
    name: 'MaintTeccategorie',
    component: () => import('../views/MaintTeccategorie.vue')
  },
  {
    path: '/MaintApplicatif',
    name: 'MaintApplicatif',
    component: () => import('../views/MaintApplicatif.vue')
  },
  {
    path: '/MaintCligadi',
    name: 'MaintCligadi',
    component: () => import('../views/MaintCligadi.vue')
  },
  {
    path: '/MaintCharge',
    name: 'MaintCharge',
    component: () => import('../views/MaintCharge.vue')
  },
  {
    path: '/MaintLogappli',
    name: 'MaintLogappli',
    component: () => import('../views/MaintLogappli.vue')
  },
  {
    path: '/MaintLogmodule',
    name: 'MaintLogmodule',
    component: () => import('../views/MaintLogmodule.vue')
  },
  {
    path: '/MaintFilogin',
    name: 'MaintFilogin',
    component: () => import('../views/MaintFilogin.vue')
  },
  {
    path: '/MaintFougadi',
    name: 'MaintFougadi',
    component: () => import('../views/MaintFougadi.vue')
  },
  {
    path: '/MappingFougadi',
    name: 'MappingFougadi',
    component: () => import('../views/MappingFougadi.vue')
  },
  {
    path: '/MaintAdherent',
    name: 'MaintAdherent',
    component: () => import('../views/MaintAdherent.vue')
  },
  {
    path: '/MaintMagasin',
    name: 'MaintMagasin',
    component: () => import('../views/MaintMagasin.vue')
  },
  {
    path: '/MappingCligadiMetier',
    name: 'MappingCligadiMetier',
    component: () => import('../views/MappingCligadiMetier.vue')
  },
  {
    path: '/MaintRayon',
    name: 'MaintRayon',
    component: () => import('../views/MaintRayon.vue')
  },
  {
    path: '/PrioriteFournisseur',
    name: 'PrioriteFournisseur',
    component: () => import('../views/PrioriteFournisseur.vue')
  },
  {
    path: '/MaintAdhmetier',
    name: 'MaintAdhmetier',
    component: () => import('../views/MaintAdhmetier.vue')
  },
  {
    path: '/ArborescenceShop',
    name: 'ArborescenceShop',
    component: () => import('../views/ArborescenceShop.vue')
  },
  {
    path: '/MaintAdhcatmet',
    name: 'MaintAdhcatmet',
    component: () => import('../views/MaintAdhcatmet.vue')
  },
  {
    path: '/MaintAdhfoumet',
    name: 'MaintAdhfoumet',
    component: () => import('../views/MaintAdhfoumet.vue')
  },
  {
    path: '/MaintParamsiv',
    name: 'MaintParamsiv',
    component: () => import('../views/MaintParamsiv.vue')
  },
  {
    path: '/MaintLinktecdoc',
    name: 'MaintLinktecdoc',
    component: () => import('../views/MaintLinktecdoc.vue')
  },
  {
    path: '/MaintProprietaire',
    name: 'MaintProprietaire',
    component: () => import('../views/MaintProprietaire.vue')
  },
  {
    path: '/MaintIaref',
    name: 'MaintIaref',
    component: () => import('../views/MaintIaref.vue')
  },
  {
    path: '/MaintProfilpneu',
    name: 'MaintProfilpneu',
    component: () => import('../views/MaintProfilpneu.vue')
  },
  {
    path: '/MaintExclumarque',
    name: 'MaintExclumarque',
    component: () => import('../views/MaintExclumarque.vue')
  },
  {
    path: '/MaintWtransmag',
    name: 'MaintWtransmag',
    component: () => import('../views/MaintWtransmag.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router